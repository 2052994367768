import { Component, OnInit } from '@angular/core';
import { Analytics } from './analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'YNRA';
  constructor( private analytics: Analytics) { }
  ngOnInit(): void {
    this.analytics.logEvent("site_start");
  }
}
