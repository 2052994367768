import { Component, OnInit, Input} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
import { Analytics } from '../../analytics';
interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'dialog',
  templateUrl: './dialog.html',
})
export class Dialog {
  constructor(private modalService: NgbModal) { }
  @Input() user;
  close(){
    this.modalService.dismissAll();
  }
 }

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  closeResult = '';
  constructor(private http: HttpClient, private fb: FormBuilder, private modalService: NgbModal, private analytics: Analytics, public dialog: MatDialog) { }
  public work: any = 'Running tracker'
  nav = [
    {
      img: 'assets/images/ynra_sito-icona-GPStracker.svg',
      title: 'Running tracker'
    },
    {
      img: 'assets/images/ynra_sito-icona-HubOrganizz.svg',
      title: 'Hub organizzativo'
    },
    {
      img: 'assets/images/ynra_sito-icona-Percorsi.svg',
      title: 'Un generatore di percorsi'
    },
    {
      img: 'assets/images/ynra_sito-icona-Gare.svg',
      title: 'Iscrizione alle gare'
    },
  ]

  content = [
    {
      img: 'assets/images/Ynra_sito-APP_runningtracker.png',
      title: 'Running tracker',
      text: 'Il running tracker è pratico ed essenziale: permette di scegliere tra una corsa libera, una corsa attraverso un percorso importato o una safe run (tragitti certificati come sicuri in quanto privi di terreno sconnesso, ben illuminati e frequentati)'
    },
    {
      img: 'assets/images/Ynra_sito-APP_hub_organizzativo.png',
      title: 'Hub organizzativo',
      text: 'L’hub organizzativo permette di condividere i propri percorsi con il pubblico, così da poterli votare o aggiungere segnalazioni relative a servizi (bagni, fontanelle) o disservizi (buche, strade chiuse). Sarà possibile anche gestire la propria Crew di appartenenza, creando una sorta di “Club House” privata.'
    },
    {
      img: 'assets/images/Ynra_sito-APP_percorso2.png',
      title: 'Un generatore di percorsi',
      text: 'Il generatore di percorsi è la creatività data in mano allo sportivo: realizza i tuoi percorsi dall’interno dell’applicazione, in maniera pratica ed intuitiva grazie ad una tecnologia performante e fatti votare le creazioni. Più voti significano più punti, più punti vuol dire più premi!'
    },
    {
      img: 'assets/images/Ynra_sito-APP_gare.png',
      title: 'Iscrizione alle gare',
      text: 'L’iscrizione alle gare prevede una lista di tutti gli eventi disponibili sul territorio nazionale che potrà essere incrementata dagli stessi organizzatori che vorranno promuovere il loro evento.'
    },
  ]
  openWorkSlide(val) {
    this.work = val
  }
  modalHeader: string;
  initForm: FormGroup;
  submitted = false;
  mailChimpEndpoint = 'https://ynra.us18.list-manage.com/subscribe/post-json?u=636c52ade3b6a46ff5f6e9086&amp;id=5b88779725';
  error = '';
  isCollapsed = true;
  changeText;
  changeText1;
  public user = {
    name: 'REGISTRAZIONE AVVENUTA CON SUCCESSO',
    name1: 'INSERIRE UNA EMAIL VALIDA',
    name2: 'REGISTRAZIONE GIÀ AVVENUTA CON QUESTA EMAIL'
  }
  ngOnInit() {
    this.initForm = this.createFormConditions();
  }
  analyticsvideo() {
    this.analytics.logEvent("video_click");
  }

  open(content1) {
    this.modalService.open(content1, { centered: true, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createFormConditions() {
    return this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$')]],
    });
  }
  analyticsfacebook() {
    this.analytics.logEvent("social_facebook_click");
  }
  analyticsinstagram() {
    this.analytics.logEvent("social_instagram_click");
  }
  invia(form) {
    console.log(form.controls['email'].status);
    this.error = '';
    if (form.controls['email'].status === 'VALID') {
      const params = new HttpParams()
        .set('EMAIL', form.controls['email'].value)
        .set('636c52ade3b6a46ff5f6e9086', '');

      const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;

      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.analytics.logEvent("subscribe_top");
          this.submitted = true;
          const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
          modalRef.componentInstance.user = this.user.name;
        }
        else {
          const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
          modalRef.componentInstance.user = this.user.name2;
          this.error = response.msg;
        }
      }, error => {
        console.error(error);
        this.error = 'Sorry, an error occurred.';
      });
    } else {
      const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
      modalRef.componentInstance.user = this.user.name1;
    }
  }
  invia1(form) {
    console.log(form.controls['email'].status);
    this.error = '';
    if (form.controls['email'].status === 'VALID') {
      const params = new HttpParams()
        .set('EMAIL', form.controls['email'].value)
        .set('636c52ade3b6a46ff5f6e9086', '');

      const mailChimpUrl = `${this.mailChimpEndpoint}&${params.toString()}`;

      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.analytics.logEvent("subscribe_bottom");
          this.submitted = true;
          const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
          modalRef.componentInstance.user = this.user.name;
        }
        else {
          const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
          modalRef.componentInstance.user = this.user.name2;
          this.error = response.msg;
        }
      }, error => {
        console.error(error);
        this.error = 'Sorry, an error occurred.';
      });
    } else {
      const modalRef = this.modalService.open( Dialog, { centered: true, ariaLabelledBy: 'modal-basic-title' });
      modalRef.componentInstance.user = this.user.name1;
    }
  }
}
