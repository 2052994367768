
<nav class="navbar navbar-expand-lg navbar-light menus fixed-top">
  <a class="d-inline-block m-r-auto" style="float:left;">
      <img src="assets/images/YNRA_logoRGB_neg-orizz.png" alt="" class="img-fluid">
  </a>
  <button class="navbar-toggler btn btn-outline-primary" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"  aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarTogglerDemo02">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02" [ngbCollapse]="isCollapsed">
    <div class="container">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="d-inline-block mr-5" href="#">
              <p>HOME</p>
          </a>      </li>
        <li class="nav-item">
          <a class="d-inline-block mr-5" href="#servizi">
              <p>LE DUE ANIME DI YNRA</p>
          </a>      </li>
        <li class="nav-item">
          <a class="d-inline-block mr-5" href="#terzo">
              <p>DISPOSITIVO GPS</p>
          </a>      </li>
          <li class="nav-item">
            <a class="d-inline-block mr-5" href="#quarto">
                <p>APP MOBILE</p>
            </a>     </li>
      </ul>
    </div>
  </div>
</nav>
