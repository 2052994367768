import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AngularTiltModule } from 'angular-tilt';
import 'hammerjs';
import 'mousetrap';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomeNavComponent } from './home-nav/home-nav.component';
import { HomeComponent } from './home.component';

// Gym Layout



import { NgxMasonryModule } from 'ngx-masonry';

@NgModule({
  declarations: [
     HomeComponent,HomeNavComponent, HomeHeaderComponent],

  imports: [
    CommonModule,
    CarouselModule,
    NgbModule,
    GalleryModule.forRoot(),
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    MasonryGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxMasonryModule,
  ],

  exports: [
]
})

export class HomeModule { }
