import { Injectable } from "@angular/core";
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { environment } from '../environments/environment';

@Injectable()
export class Analytics {

  analytics: firebase.analytics.Analytics;

  constructor() {
    firebase.initializeApp(environment.firebase);
    this.analytics = firebase.analytics();
  }

  logEvent(eventName: string, eventParams?: { [key: string]: any }, options?: firebase.analytics.AnalyticsCallOptions) {
    this.analytics.logEvent(eventName, eventParams, options);
  }

}
