<!-- header Start-->


<div class="gym" style="background-image: url('assets/images/Immagine-Sfondo-sito-Ynra-c-scuro.jpg');background-position: 35% 10%;">
  <div class="container containermain" style="padding-top:20vh;padding-bottom:200px;">
    <div class="row">
      <div class="col-md-7 col-sm-12 col-12" style="padding-top:30px;">
        <h1>You'll never run alone</h1>
        <p class="p1">Hardware e Software per la sicurezza del runner urbano</p>
        <p class="p2">YNRA è un ambiente di safety progettato per gli sportivi, composto da una applicazione per smartphone ed un dispositivo GPS. Riprendetevi la sicurezza che avreste praticando attività sportiva in gruppo, anche quando siete
          da soli.</p>
        <h2 class="bottone">coming soon</h2>
      </div>
      <div class="col-md-5 col-sm-12 col-12 videomain" style="margin:auto;">
        <iframe width="100%" height="315" (click)="analyticsvideo()" src="https://www.youtube.com/embed/NfomFSiF9LI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="row footermaster">
    <div class="col-12 footerfirst">
      <div class="newsletter">
        <div class="col-12">
          <p class="p2">Inserisci la tua mail per ottenere uno sconto sul servizio quando sarà disponibile</p>
        </div>
        <div class="col-md-6 offset-md-3" style="margin-top:20px;">
          <form [formGroup]="initForm">
            <div class="input-group">
              <input type="text" name="EMAIL" id="EMAIL" formControlName="email" class="inputnews required  form-control" placeholder="Inserisci la tua email" required aria-label="Recipient's username" aria-describedby="input">
              <div class="input-group-append">
                <span class="input-group-text" type="submit" value="Subscribe" (click)="invia(initForm);" name="subscribe" id="input">Iscriviti</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="" id="servizi">
  <div class="container">
    <div class="row">
      <div class="col-md-8 consulenze">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="title mt-3 mb-0">
              <h2 class="anime text-center mb-1">LE DUE ANIME DI ynrA</h2>
              <h6 class="subanime text-center mt-3">YNRA risponde alla domanda di sicurezza sempre maggiore richiesta dagli sportivi urbani. Dal connubio di un dispositivo GPS ed una applicazione, nasce un ambiente di safety indicato per i runner, i ciclisti, i pattinatori o i trekker, ed in generale chiunque si appresti ad affrontare un’attività sportiva outdoor.</h6>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <p class="call">ISCRIVITI ALLA NEWSLETTER</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="" style="padding:0;">
  <div class="row mr-0 ml-0">
    <div class="col-6 col-md-6 p-0">
      <div class="content" (mouseover)="changeText=true" (mouseout)="changeText=false">
        <div class="content-overlay"></div>
        <img class="content-image" src="assets/images/ynra_mockup-mano+device-01_2000px.jpg">
        <div class="content-details fadeIn-left">
          <h3 class="intern">dispositivo</h3>
        </div>
      </div>
    </div>
    <div class="col-6 col-md-6 p-0">
      <div class="content" (mouseover)="changeText1=true" (mouseout)="changeText1=false">
        <div class="content-overlay"></div>
        <img class="content-image" src="assets/images/ynra_mockup-mano+app-00_2000px.jpg">
        <div class="content-details fadeIn-left">
          <h3 class="intern">applicazione</h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dispositivo" id="terzo">
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 col-md-12">
        <h2 class="anime pb-3">IL DISPOSITIVO GPS</h2>
        <h6 class="subanime text-center mt-3">La prima delle due anime che compongono YNRA: il dispositivo rappresenta la sicurezza portatile di cui ogni sportivo ha bisogno.</h6>
      </div>
      <div class="col-md-12 mt-4">
        <p class="call">ISCRIVITI ALLA NEWSLETTER</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mt-5">
        <img class="imgmob" src="assets/images/YNRA_sticker-device012speculare1.png" height="600px" alt="">
      </div>
      <div class="col-md-6 col-12 mt-4 mt-5 textmain">
        <p class="textp">Progettato per tutti quegli sportivi che vogliono praticare attività senza eccessivi pesi o essere disturbati da chiamate e messaggi, si tratta di un GPS tracker munito di speaker e microfono.
Una volta abbinato all'applicazione e configurato, risulta completamente indipendente da essa e dallo smartphone in generale.<br><br>
La pressione del bottone in caso di emergenza permette di richiedere assistenza diretta grazie ad una chiamata vocale e di essere geolocalizzati da una centrale operativa 24/7.
La notifica di una richiesta di aiuto potrà arrivare anche sui telefoni di familiari e amici dello sportivo che vengono eletti come utenti fidati.</p>
      </div>
    </div>
  </div>
</section>


<section class="dispositivo2" id="quarto">
  <div class="container">
    <div class="row mb-4">
      <div class="col-12 col-md-12">
        <h2 class="anime2 pb-3">L'APPLICAZIONE PER SMARTPHONE</h2>
        <h6 class="subanime2 text-center mt-3">Un app per smartphone rapida ed intuitiva, con funzionalità realmente utili</h6>
      </div>
      <div class="col-md-12 mt-4">
        <p class="call">ISCRIVITI ALLA NEWSLETTER</p>
      </div>
    </div>
    <div class="row">
            <div class="col-6 col-md-3" *ngFor="let data of nav">
              <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': work == data.title }" (click)="openWorkSlide(data.title)">
                <img class="imgnorm" height="59" [src]="data.img" alt="tab-image-1">
                <h6 class="subanime2 subhover pt-3">{{data.title}}</h6>
              </a>
            </div>
      </div>
      <div class="work-tab-bg work-content p-t-50">
        <div class="tab-content text-center" id="pills-tabContent">
          <div class="tab-pane fade show active" *ngFor="let data of content" [ngClass]="{'active show': work == data.title }" id="pills-work1" role="tabpanel">
            <div class="row">
              <div class="col-md-6 textmain">
                <p class="textp" style="white-space:pre-wrap">{{data.text}} </p>
              </div>
              <div class="col-md-6">
                <img [src]="data.img" alt="tab-image-1" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

<section class="p-0">
    <img src="assets/images/Ynra-img-anteprima_video.jpg" alt="" width="100%">
    <div class="centered">
      <p class="textp">Guarda il video promo di YNRA</p>
      <p class="call pt-2" style="text-align:center;cursor:pointer;" (click)="open(content1)">PLAY</p>
    </div>


    <ng-template #content1 let-modal>
    <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">VIDEO PROMO DI YNRA</h4>
    </div>
    <div class="modal-body">
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/qctBLSF-oAI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    </ng-template>
</section>





<div class="row footermaster">
  <div class="col-12 footerfirst1">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 text-center">
          <h4 class="textfoot pt-5">Cosa aspetti?</h4>
          <p class="text1foot pt-3 pb-3">Inserisci la tua mail per ottenere uno sconto sul servizio quando sarà disponibile</p>
          <div class="col-md-6 offset-md-3" style="margin-top:20px;">
            <form [formGroup]="initForm">
              <div class="input-group">
                <input type="text" name="EMAIL" id="EMAIL" formControlName="email" class="inputnews required  form-control" placeholder="Inserisci la tua email" required aria-label="Recipient's username" aria-describedby="input">
                <div class="input-group-append">
                  <span class="input-group-text" type="submit" value="Subscribe" (click)="invia1(initForm);" name="subscribe" id="input">Iscriviti</span>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-1 col-12 col-sm-12  bottomfooter pt-4">
            <div class="iconss" style="display:flex;float:right;">
              <a (click)="analyticsinstagram()" href="https://www.instagram.com/ynraofficial/" target="_blank">
                <div class="footericon" style="background-color: #ffd300;-webkit-mask-image: url(assets/images/IG.svg);mask-image: url(assets/images/IG.svg);margin-right:20px;"></div>
              </a>
              <a (click)="analyticsfacebook()" href="https://www.facebook.com/ynraofficial" target="_blank">
                <div class="footericon" style="background-color: #ffd300;-webkit-mask-image: url(assets/images/FB.svg);mask-image: url(assets/images/FB.svg);"></div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-4 pt-5">
          <p class="pfooter1">Powered by</p>
          <hr>
          <a href="https://www.farport.co/" target="_blank">
            <div class="img1" style="background-image: url('assets/images/ynra_loghi-footer-FARPORT.svg');width:80px;height:50px;display: block;margin-left: auto;margin-right: auto;"></div>
          </a>
        </div>
        <div class="col-md-8 col-sm-4 col-4 pt-5">
          <p class="pfooter1">in partnership with</p>
          <hr>
          <a href="https://www.pumasecurity.it/" target="_blank">
            <img class="img1 mr-3 ml-3" src="assets/images/ynra_loghi-footer_PUMA-00-80x50.png" width="80px" height="50px">
          </a>
          <a href="http://www.transorsrl.com/" target="_blank">
            <img class="img1 mr-3 ml-3" src="assets/images/ynra_loghi-footer-TRANSOR.svg" width="80px" height="50px">
          </a>
          <a href="https://www.wildenherbals.com/" target="_blank">
            <img class="img1 mr-3 ml-3" src="assets/images/ynra_loghi-footer-WILDEN.svg" width="80px" height="50px">
          </a>
          <a href="http://www.womeninrun.it/" target="_blank">
            <img class="img1 mr-3 ml-3" src="assets/images/ynra_loghi-footer_WIR-00-80x50.png" width="80px" height="50px">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 footersecond">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-12 col-sm-6 bottomfooter">
          <p class="pfooter2">© Copyright 2020. Tutti I Diritti Sono Riservati</p>
        </div>
        <div class="col-md-6 col-12 col-sm-6 bottomfooter" style="text-align:end;">
          <p  class="pfooter2">Privacy | Termini e condizioni</p>
        </div>
      </div>
    </div>
  </div>
</div>
